.icon {
  width: 25px;
  height: 25px;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.input-field {
    min-height: 50px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 15px;
    padding-left: 45px;
    outline: #e6e6e6;
  
    &::placeholder {
      color: #b3b3b3;
    }
}

.icon-eye-wrapper {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 24px;
    background: #ccc;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
  }

  &.show::before {
    opacity: 1;
  }
}
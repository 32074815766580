.input-field {
    min-height: 50px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 15px;
    padding-left: 45px;
    outline: #e6e6e6;
  
    &::placeholder {
      color: #b3b3b3;
    }
}
@import "mixin";

// Import file
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700");

// style setup
:root {
  --primary: #00d68f;
  --info: #1a8cff;
  --warning: #ffbb33;
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 12px;
  line-height: 135%;
  text-rendering: optimizeSpeed;
  color: #333;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#root {
  height: 100vh;
}

.App {
  height: inherit;
}

svg {
  display: block;
  height: auto;
  width: 100%;
}

.icon {
  cursor: pointer;
}

.title-14 {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
}

.color-desc {
  color: #b3b3b3;
}

.age-range {
  .rc-slider-rail {
    height: 6px;
  }

  .rc-slider-track {
    background: var(--primary);
    height: 6px;
  }

  .rc-slider-handle {
    width: 18px;
    height: 18px;
    margin-top: -6px;
    background: var(--primary);
    border: none;
    opacity: 1;
  }
}

.limit-1-row {
  @include limitTextRows(1);
}

.menu-collapse {
  &.select-dropdown {
    .react-dropdown-select-content {
      display: none;
    }

    .react-dropdown-select {
      justify-content: center;
    }

    .react-dropdown-select-dropdown-handle {
      margin-left: 0;
    }
  }
}

.select-general {
  background: #fff;

  .react-dropdown-select-dropdown {
    top: 100%;
    margin-top: 1px;
    overflow: hidden;
    transition: 0.5s;
    max-height: 0;
  }
}
.path-in-text {
  path {
    fill: currentColor;
  }
}

@keyframes show {
  from {
    max-height: 0;
  }
  to {
    max-height: 300px;
  }
}

@keyframes hide {
  from {
    max-height: 300px;
  }
  to {
    max-height: 0;
  }
}

.option {
  &:focus + .line {
    width: 100%;
  transform-origin: center;
  }
}

.line {
  background: var(--primary);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: 0.5s;
}

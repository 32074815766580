.wrapper {
  input:checked {
    & + .checkmark {
      color: var(--primary);

      path:last-child {
        display: block;
      }
    }

    & ~ .label {
      color: var(--primary);
      font-weight: 600;
    }
  }
}

.checkmark {
  width: 12.5px;
  height: 12.5px;
  position: relative;
  color: #333;
  transition: 0.2s;
}

.wrapper {
}

.section {
  padding: 30px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background: #ffffff;

  &:not(:first-child) {
    margin-top: 20px;
  }

  .heading {
    margin-bottom: 30px;
  }
}

.selection {
  .item {
    min-height: 40px;
    border-radius: 100vmax;
    border: 1px solid #000;
    padding-inline: 25px;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.field {
}

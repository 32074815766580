.form {
  margin-top: 30px;
  
  .input-field {
    min-height: 50px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-top: 5px;
    padding: 15px;

    &::placeholder {
      color: #b3b3b3;
    }
  }

  .field {
    &:not(.has-select) {
      input,
      select,
      textarea {
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 15px;
        outline: none;
      }
    }

    &.has-select {
      input {
        &::placeholder {
          color: #b3b3b3;
          font: 12px/16px;
        }
      }
    }
  }

  > :not(:first-child) {
    margin-top: 16px;
  }
}

.wrapper {
  input {
    font: inherit;
    margin: 0;
  }
}

.select-cities-custom {
  .react-dropdown-select-content {
    flex-wrap: wrap;
    position: relative;
    column-gap: 12px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      left: 0;
      background: url(../../assets/image/Search.png) no-repeat;
      background-size: contain;
    }
  }

  .react-dropdown-select-option {
    width: fit-content;
    color: #333;
    background: #fff;
    border-radius: 100vmax;
    font: inherit;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    overflow: hidden;
    padding-inline: 12px;
    margin-left: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .react-dropdown-select-input {
    display: block;
    width: 100%;
    min-height: 32px;
    margin-top: 14px;
    margin-left: 0;
    font: inherit;
    padding-top: 14px;
    padding-left: 30px;
    border-top: 1px solid #e6e6e6;
    color: #333;

    &::placeholder {
      color: #b3b3b3;
    }
  }

  .react-dropdown-select-dropdown {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 24px * 2);
  }

  .react-dropdown-select-dropdown-handle {
    align-self: flex-end;
    margin-bottom: 5px;
    display: none;
  }

  .react-dropdown-select-option-remove {
    position: relative;
    width: 12px !important;
    height: 12px !important;
    margin-left: 6px !important;

    &::before {
      content: "";
      position: absolute;
      display: block;
      inset: 0;
      background: #fff url("../../assets/image/Close.png") no-repeat;
      background-size: contain;
    }
  }
}

.title,
.number {
  font-weight: 600;
  color: #737373;
  letter-spacing: 0.2px;
}

.item-sidebar {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0 -20px;
    transition: 0.25s;
  }

  &:hover::before,
  &.active::before {
    background: #00000011;
  }
}

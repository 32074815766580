.wrapper {
  input:checked {
    & + .checkmark {
      border-color: var(--primary);

      svg {
        opacity: 1;
      }
    }

    & ~ p {
      color: var(--primary);
      font-weight: 600;
    }
  }

  svg {
    transition: 0.5;
    opacity: 0;
  }

  p {
    transition: 0.5;
  }
}

.checkmark {
  width: 12.5px;
  height: 12.5px;
  border-radius: 4px;
  border: 1px solid currentColor;
  position: relative;
  transition: 0.5s;
}

.button {
  padding: 6px 10px;
  border-radius: 100vmax;
  margin-left: 6px;
  font-size: 10px;
  line-height: 130%;
  color: #999999;
  font-weight: 600;
  position: relative;
  overflow: hidden;

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }

  &:hover {
    background: var(--primary);
    color: #fff;

    path {
      fill: #fff;
    }
  }
}

.choose-type {
  &:hover .modal {
    opacity: 1;
    pointer-events: all;
  }
}

.modal {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: 100%;
  }
}
.tooltip-wrapper {
  &:hover .box-tooltip {
    opacity: 1;
    pointer-events: all;
  }
}

.box-tooltip {
  background: rgb(48, 48, 48);
}

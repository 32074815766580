.method {
  padding: 12px 20px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;

  &.active {
    border-color: var(--primary);
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.header {
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  padding: 16px 12px;
  padding-right: 32px;
  background: #f5f5f5;
  border-radius: 6px;
  width: 100%;
  min-height: 50px;
}

.content {
  margin-top: 20px;
  overflow: hidden;

  &.hide {
    margin-top: 0;
  }
}

.icon-field {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.info-input {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.field {
  margin-top: 12px;
}
